<template>
  <div style="background: #ebedf0;color: #000;height: 100%;">
    <div class="hed">
      <img class="mentou" :src="list.doorPic" alt />
      <div class="shijian">营业时间：{{list.tradeTime}}-{{list.restTime}}</div>
      <div class="hed_a" ref="autoHeight">
        <div class="title">{{list.name}}</div>
        <div class="xinji">
          <van-rate :size="16" allow-half class="xinc" color="#ee0a24" v-model="list.grade" readonly />
          <span class="fen">{{list.grade?list.grade.toFixed(1):''}}分</span>
        </div>
        <div class="fanwei">
          <div class="bust" v-for="item in list.businessScope" :key="item">{{item}}</div>
        </div>
        <div class="dizhi">
          <img class="diw" src="../../assets/img/dinwei.png" alt />
          <div class="dizhi_a">
            <div class="dizzhi_lj">{{list.address}}</div>
          </div>
          <div class="right tu_di">
            <div class="left red_ditu" @click="daohang">
              <img src="../../assets/img/ditu.png" alt />
              <div>地图</div>
            </div>
            <div class="left red_ditu">
              <a :href="'tel:'+list.tel1">
                <img src="../../assets/img/dianhua.png" alt />
                <div>电话</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mian" :style="{marginTop:heighttop+'px'}">
      <div class="men_img" v-if="list.storeEnvironment" @click="tomenimg(0)">
        <img :src="list.storeEnvironment[0]" alt />
        <div class="biao_img">
          <span class="left">门店环境</span>
          <span class="right">{{list.storeEnvironment.length}}</span>
        </div>
      </div>
      <div class="men_img" v-if="list.storeQualification" @click="tomenimg(1)">
        <img :src="list.storeQualification[0]" alt />
        <div class="biao_img">
          <span class="left">门店资质</span>
          <span class="right">{{list.storeQualification.length}}</span>
        </div>
      </div>
      <div class="men_img" v-if="list.storeEquipment" @click="tomenimg(2)">
        <img :src="list.storeEquipment[0]" alt />
        <div class="biao_img">
          <span class="left">门店设备</span>
          <span class="right">{{list.storeEquipment.length}}</span>
        </div>
      </div>
      <div class="men_img" v-if="list.storeProject" @click="tomenimg(3)">
        <img :src="list.storeProject[0]" alt />
        <div class="biao_img">
          <span class="left">门店项目</span>
          <span class="right">{{list.storeProject.length}}</span>
        </div>
      </div>
    </div>
    <div class="foort">
      <div class="foort_a">
        <div>门店技师</div>
        <div>网友点评</div>
      </div>
      <div class="jishi">
        <div class="jishi_a" v-for="(item,idx) in list.technicians" :key="idx" v-if="idx < 4" @click="tanYG(item)">
          <div class="jishi_img left">
            <img class="touxian" @error="imgError(citem)" v-if="item.picUrl" :src="item.picUrl" alt />
            <img class="touxian" v-else src="../../assets/img/touxiang.png" alt />
          </div>
          <div class="jishi_b">
            <div style="font-weight: bold;font-size: 13px;">{{item.name}}</div>
            <div class="nengli">{{item.type}}</div>
          </div>
          <div class="right" @click.stop="dianzang(item)">
            <img class="zang" v-if="item.flag==1" src="../../assets/img/zang.png" alt />
            <img class="zang" v-else src="../../assets/img/zang2.png" alt />
          </div>
        </div>
      </div>
      <div class="cha_gen">
        <span class="chakan" @click="tojishi" v-if="list.technicians.length > 0">查看更多技师</span>
      </div>
      <div class="pinjia">
        <div class="pinjia_a">
          <div class="left" style="font-weight: bold">网友点评</div>
          <div class="right" style="font-size: 12px;" @click="topinlun">
            <span>{{list.appraiseCount}}条评论</span>
            <van-icon style="vertical-align: middle;margin-top: -2px;" name="arrow" />
          </div>
        </div>
        <div class="pin_list" v-for="(item,index) in list.appraiseList" :key="index">
          <div class="pin_a">
            <img class="c_touxian" v-if="item.avatar" :src="item.avatar" alt />
            <img class="c_touxian" v-else src="../../assets/img/touxiang.png" alt />
          </div>
          <div class="left pin_b">
            <div class="yonghu_name">
              <span class="left">{{item.name}}</span>
              <span class="right" style="color: #999;">{{item.createdDate}}</span>
            </div>
            <div class="xinji2">
              <span class="fen2">{{item.grade?item.grade.toFixed(1):''}}分</span>
              <van-rate :size="15" allow-half class="xinc" color="#ee0a24" v-model="item.grade" readonly />
            </div>
            <div class="pinlun">{{item.content}}</div>
          </div>
        </div>
        <div class="tianjia">
          <span class="pingjia">添加评价</span>
        </div>
      </div>
    </div>
    <div style="height: 52px;"></div>
    <van-popup v-model="show" closeable position="bottom" :style="{ height: '75%' }">
      <div class="tit_h1">
        <div class="til_img">
          <img class="tt_img" v-if="form.picUrl" :src="form.picUrl" alt />
          <img class="tt_img" v-else src="../../assets/img/touxiang.png" alt />
        </div>
        <div class="t_titil">
          <div class="f_name">{{form.name}}</div>
          <div>{{form.type}}</div>
        </div>
      </div>
      <div class="tit_h2">顾客评价</div>
      <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoads" :offset="10">
        <div class="pin_list2" v-for="(item,index) in liaotianlist" :key="index">
          <div class="pin_a">
            <img class="c_touxian" v-if="item.avatar" :src="item.avatar" alt />
            <img class="c_touxian" v-else src="../../assets/img/touxiang.png" alt />
          </div>
          <div class="left pin_b">
            <div class="yonghu_name">
              <span class="left">{{item.name}}</span>
              <span class="right" style="color: #999;">{{item.createdDate}}</span>
            </div>
            <div class="xinji2">
              <!-- <span class="fen2" style="color:#ffd21e">{{item.grade?item.grade.toFixed(1):''}}分</span> -->
              <van-rate :size="15" allow-half class="xinc" color="#ffd21e" v-model="item.grade" readonly />
            </div>

            <div class="pinlun">{{item.content}}</div>
          </div>
        </div>
      </van-list>
      <div style="height: 45px;"></div>
      <div class="yuan_tuijian">推荐{{form.greatCount}}</div>
    </van-popup>
    <tabbar :active="3"></tabbar>
  </div>
</template>

<script>
import tabbar from "../../components/tabbar.vue";
import shopModel from "../../api/shop";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
import Bus from "../../components/bus.js";
export default {
  // mixins: [preventBack], //注入
  components: {
    tabbar,
  },

  data() {
    return {
      userInfo: {},
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      gid: this.$route.query.gid,
      active: 3,
      show: false,
      showts: false,
      list: {},
      form: {},
      rows: {},
      heighttop: 0,
      liaotianlist: [],
      value1: 0,
      excode: "",
      page: 0,
      size: 10,
      loading: false,
      total: 0,
      finished: false,
    };
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
      // console.log(to)
      // console.log(from)
    },
  },

  methods: {
    //去门店照片
    tomenimg(active) {
      this.$router.push({
        path: "mendian_img",
        query: {
          active: active,
          gid: this.gid,
        },
      });
    },
    //总评论列表
    topinlun() {
      this.$router.push({
        path: "comment",
        query: {
          grade: this.list.grade,
          gid: this.gid,
        },
      });
    },
    //弹出员工评论列表
    tanYG(item) {
      this.page = 0;
      this.liaotianlist = [];
      this.form = item;
      this.show = true;
      this.YGpinlunlist();
    },
    YGpinlunlist() {
      var data = {
        eid: this.form.id,
        page: this.page,
        size: this.size,
        gid: this.gid,
      };
      shopModel
        .pinlunlist(data)
        .then((e) => {
          this.rows = e.data;
          let rows = e.data;
          this.loading = false;
          this.total = e.pageInfo.total;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.liaotianlist = this.liaotianlist.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.liaotianlist.length >= this.total) {
            this.finished = true;
          }
          //   this.liaotianlist = e.data;
        })
        .catch();
    },
    onLoads() {
      this.page++;
      this.YGpinlunlist();
    },
    //图片加载错误显示
    imgError(item) {
      item.coverImg1 = require("../../assets/img/touxiang.png");
    },
    //导航
    daohang() {
      var isWxMini = window.__wxjs_environment === "miniprogram";
      if (isWxMini) {
        var data = {
          latitude: this.shangjia.positionWd,
          longitude: this.shangjia.positionJd,
          name: this.shangjia.name,
        };
        let payDataStr = JSON.stringify(data);
        wx.miniProgram.navigateTo({
          url: "/pages/map/map?payDataStr=" + payDataStr, // 微信小程序调用支付的页面
        });
      } else {
        var positionWd = Number(this.list.positionWd);
        var positionJd = Number(this.list.positionJd);
        wx.openLocation({
          latitude: positionWd, // 纬度，浮点数，范围为90 ~ -90
          longitude: positionJd, // 经度，浮点数，范围为180 ~ -180。
          name: this.list.name, // 位置名
          address: this.list.address, // 地址详情说明
          scale: 13, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
        });
      }
    },
    getConfig() {
      let that = this;
      shopModel
        .config({ url: location.href.split("#")[0], gid: this.shangjia.id })
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: ["openLocation"], // 必填，需要使用的JS接口列表
            });
            wx.ready(() => {
              // var positionWd = Number(this.list.positionWd);
              // var positionJd = Number(this.list.positionJd);
              // wx.openLocation({
              //   latitude: positionWd, // 纬度，浮点数，范围为90 ~ -90
              //   longitude: positionJd, // 经度，浮点数，范围为180 ~ -180。
              //   name: this.list.name, // 位置名
              //   address: this.list.address, // 地址详情说明
              //   scale: 13, // 地图缩放级别,整形值,范围从1~28。默认为最大
              //   infoUrl: "" // 在查看位置界面底部显示的超链接,可点击跳转
              // });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    //跳转技师列表
    tojishi() {
      this.$router.push({
        path: "yuangong_list",
        query: {
          gid: this.gid,
        },
      });
    },
    //点赞
    dianzang(item) {
      var _this = this;
      let data = {
        eid: item.id,
        type: item.flag == 0 ? 1 : 0,
        uid: this.userInfo ? this.userInfo.id : "",
        // uid: "1661"
      };
      shopModel
        .cancel(data)
        .then((e) => {
          item.flag = item.flag == 0 ? 1 : 0;
          _this.$toast(e.data);
        })
        .catch(() => loading.clear());
    },
    onLoad() {
      shopModel
        .getGarageInfo({ gid: this.gid })
        .then((e) => {
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
  },
  created() {
    var _this = this;

    // if (sessionStorage.getItem("Authorization")) {
    this.onLoad();
    // }

    //  var isWxMini = window.__wxjs_environment === 'miniprogram'
    // console.log(isWxMini)
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getConfig();
  },
  updated() {
    this.heighttop = this.$refs.autoHeight.offsetHeight;
    console.log(this.heighttop);
  },
};
</script>

<style lang="less">
body {
  background: #ebedf0;
}
.hed {
  position: relative;
}
.mentou {
  height: 180px;
  width: 100%;
}
.shijian {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  color: #fff;
  font-size: 12px;
  height: 26px;
  line-height: 26px;
  padding: 0 8px;
  margin-top: 115px;
  margin-left: 10px;
  border-radius: 13px;
  background-color: rgba(0, 0, 0, 0.5);
}
.hed_a {
  padding: 14px 10px;
  position: absolute;
  top: 0;
  left: 50%;
  width: 96%;
  margin-left: -48%;
  margin-top: 150px;
  //   height: 170px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.06);
}
.title {
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
}

.fanwei {
  margin-top: 10px;
  overflow: hidden;
}
.bust {
  font-size: 12px;
  float: left;
  margin-right: 6px;
  background: #fff2f3;
  border-radius: 3px;
  padding: 0 8px;
  line-height: 18px;
  font-family: PingFangSC-Regular;
  font-size: 11px;
  color: #df3d48;
  letter-spacing: 0;
}
.dizhi {
  border-top: 1px solid #dcdcdc;
  margin-top: 15px;
  padding-top: 15px;
}
.diw {
  width: 15px;
  float: left;
  margin-top: 2px;
}
.dizhi_a {
  float: left;
  width: 70%;
  overflow: hidden;
  border-right: 1px solid #dcdcdc;
}
.dizzhi_lj {
  float: left;
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: #333333;
  padding-right: 15px;
  letter-spacing: 0;
  line-height: 18px;
}
.tu_di {
  overflow: hidden;
}
.red_ditu {
  overflow: hidden;
  margin-right: 10px;
  width: 30px;
  text-align: center;
  font-weight: 400px;
  font-family: PingFangSC-Regular;
  font-size: 10px;
  color: #999999;
  letter-spacing: 0;
}
.red_ditu a {
  color: #999999;
}
.red_ditu img {
  margin: auto;
  display: block;
  width: 24px;
}
.red_ditu div {
  line-height: 14px;
}
.mian {
  //   height: 100px;
  margin-top: 150px;
  white-space: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  //   padding: 10px 10px;
}
.mian::-webkit-scrollbar {
  display: none;
}
.men_img {
  width: 100px;
  height: 64px;
  position: relative;
  margin-left: 10px;
  display: inline-block;
  border-radius: 4px;
}
.men_img img {
  width: 100px;
  height: 64px;
  display: block;
  border-radius: 4px;
}
.biao_img {
  font-family: PingFangSC-Regular;
  font-size: 11px;
  color: #fff;
  letter-spacing: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 9;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 0 0 4px 4px;
  padding: 0 4px;
  line-height: 18px;
  opacity: 0.8;
}
.foort {
  background: #ffffff;
  border-radius: 4px;
  width: 96%;
  margin: auto;
  //   height: 500px;
  padding-bottom: 5px;
  margin-top: 15px;
}
.foort_a {
  overflow: hidden;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #dcdcdc;
  margin: 0 12px;
}
.foort_a div {
  text-align: center;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  float: left;
  width: 50%;
  font-weight: bold;
}
.jishi_a {
  background: #ffffff;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.06);
  border-radius: 26px;
  width: 155px;
  height: 50px;
  margin-top: 15px;
  margin-left: 10px;
  float: left;
}
.jishi_a:nth-child(2n) {
  float: right;
  margin-right: 4%;
}
.jishi_a:nth-child(odd) {
  float: left;
  margin-left: 4%;
}
.touxian {
  width: 38px;
  height: 38px;
  margin-top: 6px;
  margin-left: 6px;
  border-radius: 19px;
}
.chakan {
  display: block;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #666666;
  letter-spacing: 0;
  border: 1px solid #dcdcdc;
  border-radius: 13px;
  margin: auto;
  width: 92px;
  text-align: center;
}
.jishi {
  overflow: hidden;
  padding-bottom: 15px;
}

.jishi_b {
  font-family: PingFangSC-Medium;
  font-size: 11px;
  float: left;
  color: #333333;
  letter-spacing: 0;
  margin-left: 5px;
}
.nengli {
  border: 1px solid #df3d48;
  color: #df3d48;
  padding: 0 4px;
  display: inline-block;
  line-height: 18px;
  border-radius: 3px;
  transform: scale(0.85);
  //   ransform: scale(0.83);
}
.font {
  // 10px / 12px = 0.83
  // transform: scale(0.83);
}
.zang {
  width: 24px;
  margin-top: 13px;
  margin-right: 6px;
}
.cha_gen {
  overflow: hidden;
}
.pinjia {
  margin: 20px 10px 10px 10px;
  border-top: 1px solid #dcdcdc;
}
.pinjia_a {
  overflow: hidden;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #666666;
  letter-spacing: 0;
  margin-top: 5px;
}

.pin_list {
  // margin: 15px 0;
  overflow: hidden;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  border-bottom: 1px solid #dcdcdc;
  padding: 10px 0;
}

.pinlun {
  font-size: 13px;
}
.pingjia {
  display: block;
  width: 92px;
  height: 26px;
  border: 1px solid #df3d48;
  border-radius: 13px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #df3d48;
  text-align: center;
  margin: auto;
  margin-top: 15px;
}
//-----------------弹窗-------------------
.tit_h1 {
  overflow: hidden;
  margin-top: 30px;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 10px;
}
.til_img {
  padding: 10px 20px;
  float: left;
}
.tt_img {
  width: 80px;
  height: 80px;
}
.t_titil {
  float: left;
  font-size: 13px;
  color: #666;
}
.f_name {
  font-size: 15px;
  font-weight: bold;
  margin-top: 20px;
  color: #333;
}
.tit_h2 {
  font-size: 15px;
  font-weight: bold;
  padding: 15px 20px;
}
.pin_list2 {
  // margin: 15px 0;
  overflow: hidden;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  border-bottom: 1px solid #dcdcdc;
  padding: 10px 20px;
}
.pin_b {
  width: 89%;
}
.pin_a {
  float: left;
  width: 10%;
  height: 30px;
}
.c_touxian {
  float: left;
  width: 30px;
  border-radius: 15px;
}
.yonghu_name {
  overflow: hidden;
}
.xinji {
  height: 24px;
  overflow: hidden;
  line-height: 24px;
  font-size: 26px;
  margin-top: 2px;
}
.xinji2 {
  height: 24px;
  overflow: hidden;
  line-height: 24px;
  font-size: 18px;
}
.xinc {
  font-size: 16px;
}
.fen {
  color: #ee0a24;

  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  line-height: 16px;
  //   margin-top: 0px;
  margin-left: 2px;
}
.fen2 {
  color: #ee0a24;
  font-size: 12px;
  display: inline-block;

  vertical-align: middle;
  line-height: 14px;
  margin-top: -2px;
  margin-right: 4px;
}

.yuan_tuijian {
  background: #ffd21e;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 94%;
  left: 50%;
  margin-left: -46%;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-size: 14px;
}
// --------------------------------------------
</style>
