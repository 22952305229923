import request from '@/libs/request'

const shop = {
    getGarageInfo(params) {
        return request({//在线服务-门店信息
            url: '/vehicle/garage/online/info',
            method: 'get',
            params
        })
    },
    pinlunlist(params) {
        return request({//在线服务-员工评论列表
            url: '/vehicle/garage/comment/org/list',
            method: 'get',
            params
        })
    },
    config(params) {//获取微信权限
        return request({
            url: '/vehicle/wxapi/config',
            method: 'get',
            params
        })
    },
    yuangonglist(params) {//查看员工列表
        return request({
            url: '/vehicle/org/employee/mall/list',
            method: 'get',
            params
        })
    },
    pic(params) {//在线服务-门店图片信息
        return request({
            url: '/vehicle/garage/online/pic',
            method: 'get',
            params
        })
    },
    pinlunlist(params) {//在线服务-评论列表
        return request({
            url: '/vehicle/garage/comment/list',
            method: 'get',
            params
        })
    },
    cancel(params) {//推荐/取消推荐 0:取消推荐 1:推荐
        return request({
            url: '/vehicle/org/employee/support/cancel',
            method: 'post',
            params
        })
    },
}
export default shop
